import { useDispatch } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { Dispatch } from "redux";
import { Session_Reducer_isAdmin, Session_Reducer_SetFrontendSettings, Session_Reducer_SetToken } from 'rootExports/SessionReducer';
import { AuthorizedServicesClient, LoginClient } from "WebApiClient";
import { useAuthorizedServicesClient, useLoginClient } from "./useHttpClient";
import { UserInformation, useUserInformation } from "./various";
import jwtDecode from "jwt-decode";

export class SessionHandler {

    private _authServicesclient: AuthorizedServicesClient;
    private _loginClient: LoginClient;
    private _dispatch: Dispatch<any>;
    private _userInfo: UserInformation;
    private _navigate: NavigateFunction;

    constructor(authServicesclient: AuthorizedServicesClient, loginClient: LoginClient, dispatch: Dispatch<any>, navigate: NavigateFunction, user: UserInformation) {
        this._authServicesclient = authServicesclient;
        this._dispatch = dispatch;
        this._loginClient = loginClient;
        this._userInfo = user;
        this._navigate = navigate;
        this.restoreSession();
    }

    /**
         * ✅ Get the current JWT token from sessionStorage
         */
    public getToken(): string | null {
        return sessionStorage.getItem("jwtToken");
    }

    /**
   * ✅ Private method to restore session from sessionStorage
   */
    public restoreSession(): void {
        const token = this.getToken();

        if (!token) {
            console.warn("⚠️ No token found in session storage.");
            return;  // Prevents calling jwtDecode(undefined)
        }

        try {
            const decodedToken: any = jwtDecode(token);

            if (!decodedToken || !decodedToken.exp) {
                console.error("❌ Invalid token format.");
                this.Logout();
                return;
            }

            const currentTime = Date.now() / 1000;

            if (decodedToken.exp < currentTime) {
                console.warn("⏳ Token expired. Logging out...");
                this.Logout();
                return;
            }

            this._dispatch(Session_Reducer_SetToken(token));
            this._dispatch(Session_Reducer_isAdmin(decodedToken.isAdmin));

            // ✅ Auto-refresh token before expiry
            const timeLeft = decodedToken.exp - currentTime;
            if (timeLeft > 60) {
                setTimeout(() => this.RefreshSession(), (timeLeft - 60) * 1000);
            } else {
                console.warn("⚠️ Token is expiring soon. Refreshing session.");
                this.RefreshSession();
            }
        } catch (error) {
            console.error("❌ Error decoding token:", error);
            this.Logout();
        }
    }


    /**
     * RefreshSession
     */
    public async RefreshSession(): Promise<void> {
        try {
            const response = await this._authServicesclient.refreshToken();
            if (!response || !response.success || !response.token) {
                console.warn("⚠️ Session refresh failed. Logging out.");
                this.Logout();
                return;
            }

            console.log("✅ Session refreshed.");
            sessionStorage.setItem("jwtToken", response.token);
            this._dispatch(Session_Reducer_SetToken(response.token));
            this._dispatch(Session_Reducer_isAdmin(response.isAdmin));
            this._dispatch(Session_Reducer_SetFrontendSettings(response.agentFrontendSettings));
            try {
                const decodedToken: any = jwtDecode(response.token);
                const currentTime = Date.now() / 1000;
                const timeLeft = decodedToken.exp - currentTime;

                if (timeLeft > 60) {
                    setTimeout(() => this.RefreshSession(), (timeLeft - 60) * 1000);
                } else {
                    console.warn("⚠️ Token is expiring soon. Refreshing session.");
                    this.RefreshSession();
                }
            } catch (decodeError) {
                console.error("❌ Error decoding refreshed token:", decodeError);
                this.Logout();
            }
        } catch (error) {
            console.error("❌ Error refreshing session:", error);
            this.Logout();
        }
    }


    /**
      * ✅ Logout user and clear session
      */
    public Logout(): void {
        console.log("🔴 Logging out user...");

        // ✅ Clear Redux state before removing session data
        this._dispatch(Session_Reducer_SetToken(""));
        this._dispatch(Session_Reducer_isAdmin(false));

        // ✅ Clear session storage
        sessionStorage.removeItem("jwtToken");
        sessionStorage.clear();

        // ✅ Ensure no pending timeouts for refresh
        window.localStorage.setItem("logout", Date.now().toString());

        // ✅ Redirect to login page

        const webName = localStorage.getItem("webName");
        console.log("web---", webName);

        window.location.replace(`/weblogin/${webName}`);
        console.log("✅ User logged out successfully.");
    }
}

export function useSessionHandler(): SessionHandler {
    const dispatch = useDispatch();
    const userInfo = useUserInformation();
    const authServicesClient = useAuthorizedServicesClient();
    const loginClient = useLoginClient();
    const navigate = useNavigate();

    return new SessionHandler(authServicesClient, loginClient, dispatch, navigate, userInfo);
}

/**
 * ✅ React Hook to logout user
 */
export function useLogoutSession(): void {
    const sessionHandler = useSessionHandler();
    sessionHandler.Logout();
}
import GeneralWaitScreen from "components/General/WaitScreens/GeneralWaitScreen";
import { Playground } from "components/playground";
import { useAppSelector } from "configureStore";
import { useLoginClient } from "hooks/useHttpClient";
import AvailableFaresPage from "Pages/AvailableFaresPage";
import BookingDetailsPage from "Pages/BookingDetailsPage";
import BookingManagerPage from "Pages/BookingManagerPage";
import DeepLoginPage from 'Pages/DeepLoginPage';
import { EmailSignupConfirmationPage } from "Pages/EmailSignupConfirmationPage";
import { EmailUnsubscribeConfirmationPage } from "Pages/EmailUnsubscribeConfirmationPage ";
import FareDisplayPage from "Pages/FareDisplayPage";
import LoginPage from 'Pages/LoginPage';
import TestPage from 'Pages/TestPage';
import UnauthorizedPage from 'Pages/Unauthorized';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Route } from 'react-router';
import { Navigate, Routes, To, useNavigate, useParams } from "react-router-dom";
import { Session_Reducer_isAdmin, Session_Reducer_SetFrontendSettings, Session_Reducer_SetToken } from "rootExports/SessionReducer";
import NoMatch from 'routes/NoMatch';
import "./pageTransition.css";
import HotelsPage from "Pages/HotelsPage";
import { State } from "rootExports/rootReducer";
import { useUserInformation } from "hooks/various";
import { useSessionHandler } from "hooks/useSessionHandler";
import jwtDecode from "jwt-decode";
import { TokenDTO } from "components/General/types/SessionTypes";


export const AppRoutes: React.FC<{}> = Allprops => {
  const sessionHandler = useSessionHandler();
  const isAuthenticated = !!sessionHandler.getToken();
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<LoginPage />} />
      <Route path="/playground" element={<Playground />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/weblogin/:routeWebname" element={<LoginPage />} />
      <Route path="/account/login/:routeWebname" element={<LoginPage />} />
      <Route path="/deep/:webid/:deeplinkid" element={<DeepLoginPage />} />
      <Route path="/signup/confirm/:code" element={<EmailSignupConfirmationPage />} />
      <Route path="/signup/unsubscribe/:code" element={<EmailUnsubscribeConfirmationPage />} />
      <Route path="/unauthorized" element={<UnauthorizedPage />} />

      {/* ✅ Authenticated Routes (No Need for PrivateRoute) */}
      <Route path="/auth/*" element={isAuthenticated ? <AuthorizedRoutes /> : <Navigate to="/login" />} />

      {/* <Route path="/auth/*" element={<AuthorizedRoutes />} /> */}
      {/* <Route path="/auth/:webid/:deeplinkid/*" element={<AuthorizedRoutes />} /> */}

      {/* Catch-all route */}
      <Route path="*" element={<NoMatch />} />
    </Routes>
  )
}

export const RequireAuth: React.FC<{ isAuthenticated: boolean, children: JSX.Element, redirectTo: To }> = ({ children, redirectTo, isAuthenticated }) => {
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}


export const AuthorizedRoutes: React.FC<{}> = Allprops => {

  // const isLoggedIn = useAppSelector((state) => state.Session.isLoggedIn);
  const isLoggedIn = useSelector((state: any) => state.Session.isLoggedIn);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginClient = useLoginClient();
  const sessionHandler = useSessionHandler();

  const showBookingManagerTab = useSelector(
    (state: State) => state.Session.FrontendSettings.showBookingManagerTab
  );

  const sessionData = useSelector(
    (state: State) => state.Session
  );

  const otherSettings: string = useAppSelector((state: any) => {
    if (
      state.Session.FrontendSettings.otherSettingsAsKeyValueText == null ||
      state.Session.FrontendSettings.otherSettingsAsKeyValueText.trim() === ""
    ) {
      return state.Session.FrontendSettings.otherSettingsAsKeyValueText ===
        "menuPosition=L\nshowFareHeader=Off"
        ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse"
        : `menuPosition=L/{"textColor":"#ffffff","bgColor":"#242424"}
        showFareHeader=Off
        showSmartCalender=None/n[]/nfalse/nfalse{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}\n0\nshowHotelTab=None\nshowCabinContainer=All`;
    }

    return state.Session.FrontendSettings.otherSettingsAsKeyValueText;
  });

  const settingArr = otherSettings.split("\n");
  const fieldContainer = settingArr[7] != undefined ? settingArr[7].split("#") : [];
  const mustLoginBool: boolean = fieldContainer?.includes('MUSTLOGIN');
  const LoginReduxBool: any = useAppSelector((state: any) => state.LoginBool);
  let { webid, deeplinkid } = useParams();
  const userInfo = useUserInformation();


  const loginBoolStr = sessionStorage.getItem("loginBool");
  const isLogin = loginBoolStr ? JSON.parse(loginBoolStr) : false;

  /** ✅ Restore session on page refresh */
  useEffect(() => {
    sessionHandler.restoreSession(); // ✅ Calls session restore function
  }, []);

  useEffect(() => {
    if (isLoggedIn || !webid || !deeplinkid) return; // ✅ Prevent infinite loop

    const handleLogin = async () => {
      try {
        const response = await loginClient.loginByDeeplink(webid!, deeplinkid!, null);
        if (response?.success) {
          if (response.token) {
            sessionStorage.setItem("", response.token)
            const decodeToken = jwtDecode<TokenDTO>(response.token);
            sessionStorage.setItem("webName", decodeToken.WebName);

          }
          dispatch(Session_Reducer_isAdmin(response.isAdmin!));
          dispatch(Session_Reducer_SetToken(response.token!));
          dispatch(Session_Reducer_SetFrontendSettings(response.agentFrontendSettings!));
        } else {
          navigate("/unauthorized");
        }
      } catch (error) {
        console.error("Login failed:", error);
        navigate("/unauthorized");
      }
    };

    handleLogin();
  }, [isLoggedIn, webid, deeplinkid, loginClient, dispatch, navigate]);



  if (!isLoggedIn) return <GeneralWaitScreen show={true} />;


  return (<Routes>
    <Route path="/faresearch" element={
      <AvailableFaresPage />
    } />
    <Route path="/availfares" element={
      <AvailableFaresPage />
    } />
    <Route path="/hotels" element={
      <HotelsPage />
    } />
    <Route path="/faredisplay" element={
      <FareDisplayPage />
    } />
    <Route path="/bookingmanager/*" element={
      <BookingManagerPage />
    } />
    <Route
      path="/bookingManager"
      element={
        showBookingManagerTab ? <BookingManagerPage /> : <Navigate to="../availfares" />
      }
    />
    <Route path="/bookingdetails/:cartid/:bookingid" element={
      <BookingDetailsPage />
    } />
    <Route path="/bookingdetails/:filekey" element={
      <BookingDetailsPage />
    } />
    <Route path="/test/:logo/:pagePath" element={<TestPage />} />
    <Route path="*" element={<NoMatch />} />
  </Routes>
  )
}
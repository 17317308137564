import { Fragment, useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export const VersionDifferenceModal: React.FC = () => {
    const [open, setOpen] = useState(false);
    const [acknowledged, setAcknowledged] = useState(false);

    const session = useSelector((state: any) => state.Session);
    const { VersionDifferenceToBackend, NewBackendVersion, DeployInfo } = session;

    const clearCacheData = useCallback(() => {
        localStorage.clear();
        sessionStorage.clear();
        caches.keys()
            .then(keys => Promise.all(keys.map(key => caches.delete(key))))
            .finally(() => setTimeout(() => {
                window.location.replace(`${window.location.origin}${window.location.pathname}?cache_bust=${Date.now()}`);
            }, 300)); // Delay to ensure cache clearing
    }, []);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.ctrlKey && event.key === "F5") {
                event.preventDefault(); // Prevent default reload
                clearCacheData();
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [clearCacheData]);

    useEffect(() => {
        if (!DeployInfo?.Version || !NewBackendVersion) return; // ✅ Prevents running before state is ready

        const currentVersion = DeployInfo.Version.trim().toLowerCase();
        const backendVersion = NewBackendVersion.trim().toLowerCase();

        // ✅ Ensure modal only opens if the backend version is higher
        if (
            VersionDifferenceToBackend &&
            !acknowledged &&
            currentVersion !== backendVersion &&
            currentVersion < backendVersion // 🚀 Ensures only older versions trigger the modal
        ) {
            if (window.self !== window.top) {
                setOpen(false);
                clearCacheData();
            } else {
                setOpen(true);
            }
        } else {
            setOpen(false); // ✅ Ensures modal stays hidden if versions match or current version is greater
        }
    }, [VersionDifferenceToBackend, NewBackendVersion, DeployInfo, acknowledged, clearCacheData]);



    const OnToggleClose = () => {
        setOpen(false);
        setAcknowledged(true);
    };

    return (
        <Fragment>
            {VersionDifferenceToBackend && (
                <div className="alert alert-warning fw-bold">
                    <span className="me-2">An update is available!</span>
                    <div className="btn-group btn-group-sm">
                        <button className="btn btn-primary me-2" onClick={clearCacheData}>Reload now</button>
                        <button className="btn btn-sm btn-secondary" onClick={() => setOpen(true)}>Show details</button>
                    </div>
                </div>
            )}

            <Modal size="xl" isOpen={open} toggle={OnToggleClose}>
                <ModalHeader className="text-center" toggle={OnToggleClose}>
                    A new Version of Agent is available!
                </ModalHeader>
                <ModalBody>
                    <div className="alert alert-warning">
                        <h5 className="text-center">You are using an outdated version of Agent</h5>
                        <p className="text-center">
                            New Version: {NewBackendVersion} <span className="text-muted">(Your Version: {DeployInfo?.Version})</span>
                        </p>
                        <p className="fw-bold">To avoid incorrect application behavior, a reload is required.</p>
                        <div className="d-grid">
                            <button className="btn btn-primary" onClick={clearCacheData}>Click to reload this page.</button>
                        </div>
                    </div>
                    <div>
                        <p>If this message keeps appearing, try the following steps to clear your browser cache:</p>
                        <ul>
                            <li>Click the button above to reload the page.</li>
                            <li>Open the page in a new tab or window.</li>
                            <li>Press <kbd>Ctrl + F5</kbd> on your keyboard.</li>
                            <li>Ask your IT support for help.</li>
                            <li>
                                If no IT support is available, instructions can be found <a href="https://www.refreshyourcache.com/en/home/" target="_blank" rel="noopener noreferrer">here</a>.
                            </li>
                            <li>If none of the above steps worked, contact Hitchhiker support.</li>
                        </ul>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-secondary" onClick={OnToggleClose}>Later</button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

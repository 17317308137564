import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTicket } from "@fortawesome/pro-regular-svg-icons";
import { useBookingClient } from "hooks/useHttpClient";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface ConfirmTicketModalProps {
  Booking: any;
  Toggle: () => void;
  IsOpen: boolean;
  OnSync: (callback?: () => void) => void;
  GetBookingsWithCurrentFilter: () => void;
}

const SplitBookingModal: React.FC<ConfirmTicketModalProps> = ({ Booking, Toggle, IsOpen, OnSync, GetBookingsWithCurrentFilter }) => {
  return (
    <Modal size="xl" style={{ width: "45%" }} className="customPopupDesign" centered toggle={Toggle} isOpen={IsOpen}>
      <ModalHeader toggle={Toggle}>
        Split passenger for <strong>{Booking.latestVersion.generalInfo.filekey}</strong>
      </ModalHeader>
      <ModalBody>
        <PassengerTable Booking={Booking} Toggle={Toggle} OnSync={OnSync} GetBookingsWithCurrentFilter={GetBookingsWithCurrentFilter} />
      </ModalBody>
    </Modal>
  );
};

const PassengerTable: React.FC<{ Booking: any; Toggle: () => void; OnSync: (callback?: () => void) => void; GetBookingsWithCurrentFilter: () => void; }> = ({ Booking, Toggle, OnSync, GetBookingsWithCurrentFilter }) => {
  const flightDetails = Booking.latestVersion?.flightDetailsInfo;
  const passengers = flightDetails?.passengers || [];
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);
  const bookClient = useBookingClient();

  const columns: GridColDef[] = [
    { field: "passengerNo", headerName: "No.", width: 50 },
    { field: "passengerName", headerName: "Passenger Name", width: 180 },
    { field: "filekey", headerName: "Filekey", width: 100 },
    { field: "statusBooking", headerName: "Status Booking", width: 120 },
    { field: "dateFirstDeparture", headerName: "First Departure", width: 120 },
    { field: "PassengerType", headerName: "Passenger Type", width: 120 },
  ];
  console.log("passenger", passengers);

  const rows = passengers.map((passenger: any, index: number) => ({
    id: index, // MUI requires a unique ID
    passengerID: passenger.passengerID, // Actual passenger ID for API
    passengerNo:passenger.passengerNumber,
    passengerName: `${passenger.firstName} ${passenger.lastName}`,
    filekey: Booking.latestVersion.generalInfo.filekey,
    statusBooking: passenger.ticketNumbers.length > 0 ? "Ticketed" : "Pending",
    dateBooking: moment(Booking.latestVersion.generalInfo.bookingDate).format("DD-MM-YYYY"),
    dateFirstDeparture: moment(Booking.latestVersion.generalInfo.firstDepartureDate).format("DD-MM-YYYY"),
    PassengerType: passenger.type,
  }));

  async function SubmitTicket() {
    if (selectedRows.length === 0) return;
    setLoading(true);

    const identifier = Booking.latestVersion?.bookingIdentifier;
    if (!identifier) {
      toast.error("Booking identifier is missing!");
      setLoading(false);
      return;
    }

    try {
      await bookClient.splitBooking(identifier, selectedRows);
      toast.success("Passenger successfully split!");

      // Wrap OnSync in a Promise to ensure it completes before moving forward
      OnSync(() => {
        setTimeout(() => {
          GetBookingsWithCurrentFilter();
        }, 1000);
      });

      Toggle(); // Close the modal on success
    } catch (error) {
      console.error("Error in Split Booking:", error);
      toast.error("Failed to split passenger. Please try again.");
    } finally {
      setLoading(false);
      GetBookingsWithCurrentFilter(); // Runs after OnSync is completed
    }





  }

  return (
    <div style={{ height: "auto", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        checkboxSelection
        onSelectionModelChange={(ids) => setSelectedRows(ids.map(Number))}
        hideFooter
      />
      <Button
        color="success"
        className="text-white mt-3"
        block
        onClick={SubmitTicket}
        disabled={selectedRows.length === 0 || loading}
      >
        {loading ? (
          <>
            <Spinner size="sm" color="light" className="me-2" />
            Splitting...
          </>
        ) : (
          <>
            <FontAwesomeIcon style={{ color: "white" }} icon={faTicket} /> YES, Split passenger
          </>
        )}
      </Button>
    </div>
  );
};

export default SplitBookingModal;

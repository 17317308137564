import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  DefaultSessionState,
  SessionState,
  WaitScreenProgress,
} from "components/General/types/SessionTypes";
import { } from "Constants";
import { DeployInfo } from "hooks/GetAppInfo";
import jwtDecode from "jwt-decode";
import {
  AgentFrontendSettings,
  BaseApiResponseMetaData,
  PaymentDataSettingsClient,
} from "WebApiClient";

const initialState: SessionState = { ...DefaultSessionState };

export const SessionSlice = createSlice({
  name: "counter",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    Session_Reducer_SetDeployInfo: (
      state,
      action: PayloadAction<DeployInfo>
    ) => {
      state.DeployInfo = action.payload;
    },
    Session_Reload: (state, action) => {
      state.VersionDifferenceToBackend = action.payload;
    },
    Session_Reducer_Reset: (state) => {
      state = { ...DefaultSessionState };
    },
    Session_Reducer_SetToken: (state, action: PayloadAction<string>) => {
      const token = action.payload;

      // ✅ Check if the token is valid
      if (!token || token.trim().length === 0) {
        console.error("❌ Received an empty or invalid token.");
        return;
      }

      try {
        const decodedToken: any = jwtDecode(token);

        if (!decodedToken || !decodedToken.WebName || !decodedToken.appversion) {
          console.error("❌ Invalid token format.");
          return;
        }

        const webName: string = decodedToken.WebName;
        const backendAppversion: string = decodedToken.appversion;
        const deeplink: string = decodedToken.deeplink;
        const webID: string = decodedToken.WebId;
        const paymentDataSetting = decodedToken.paymentDataSetting;
        let versionDifferenceToBackend = false;

        if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
          versionDifferenceToBackend = false;
        } else {
          versionDifferenceToBackend = backendAppversion !== state.DeployInfo?.Version;
        }

        if (versionDifferenceToBackend) {
          state.VersionDifferenceToBackend = true;
          state.NewBackendVersion = backendAppversion;
        }

        state.webID = webID;
        state.isLoggedIn = true;
        state.JwtToken = token;
        state.WebName = webName;
      } catch (error) {
        console.error("❌ Error decoding token:", error);
      }
    },
    Session_Reducer_SetFrontendSettings: (
      state,
      action: PayloadAction<AgentFrontendSettings>
    ) => {
      state.FrontendSettings = action.payload;
    },
    Session_Reducer_ShowCustomerWaitScreen: (state) => {
      state.ShowCustomerWaitScreen = true;
    },
    Session_Reducer_PaymentDataSetting: (state) => {
      state.ShowCustomerWaitScreen = true;
    },
    Session_Reducer_isAdmin: (state, action) => {
      state.isAdmin = action.payload;
    },
    Session_Reducer_HideCustomerWaitScreen: (state) => {
      state.ShowCustomerWaitScreen = false;
    },
    Session_Reducer_ShowGeneralWaitScreen: (state) => {
      state.ShowGeneralWaitScreen = true;
      state.WaitscreenProgress = { Percentage: 0, Label: "" };
    },
    Session_Reducer_HideGeneralWaitScreen: (state) => {
      state.ShowGeneralWaitScreen = false;
      state.WaitscreenProgress = { Percentage: 0, Label: "" };
    },
    Session_Reducer_SetWatiScreenInfo: (
      state,
      action: PayloadAction<WaitScreenProgress>
    ) => {
      state.WaitscreenProgress = action.payload;
    },
    Session_Reducer_PushTransaction: (
      state,
      action: PayloadAction<BaseApiResponseMetaData>
    ) => {
      state.Transactions.unshift(action.payload);
      state.Transactions.splice(10, 1); // save max 10 TIDS
    },
  },
});

export const {
  Session_Reducer_SetDeployInfo,
  Session_Reducer_Reset,
  Session_Reducer_SetToken,
  Session_Reducer_HideCustomerWaitScreen,
  Session_Reducer_HideGeneralWaitScreen,
  Session_Reducer_PushTransaction,
  Session_Reducer_SetFrontendSettings,
  Session_Reducer_SetWatiScreenInfo,
  Session_Reducer_ShowCustomerWaitScreen,
  Session_Reducer_ShowGeneralWaitScreen,
  Session_Reducer_isAdmin,
  Session_Reload,
} = SessionSlice.actions;

import { DeepLoginData, TokenDTO } from "components/General/types/SessionTypes";
import { useLoginClient } from "hooks/useHttpClient";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { State } from "rootExports/rootReducer";
import { Session_Reducer_HideGeneralWaitScreen, Session_Reducer_SetFrontendSettings, Session_Reducer_SetToken } from 'rootExports/SessionReducer';
import { StartPage } from "WebApiClient";


const DeepLoginPage = ({ }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const IsLoggedIn = useSelector((state: State) => state.Session.isLoggedIn);
  const loginClient = useLoginClient();

  const [errorMessage, setErrorMessage] = useState("");
  const { webid, deeplinkid } = useParams();
  const LoginFailed = errorMessage !== "";

  useEffect(() => {
    document.title = `deep`;
    let f = window.location.search;
    // TryLogin({ WebID: webid, Deeplink: deeplinkid, Query: f })
    DeepLogin({ Deeplink: deeplinkid!, Query: f, WebID: webid! })
  }, []);

  function DeepLogin(loginData: DeepLoginData) {
    const stringQuery = loginData.Query;
    let universalTempDataID: string | null = null;
    let queryParams: URLSearchParams | undefined = undefined;

    if (stringQuery && stringQuery.length > 0) {
      queryParams = new URLSearchParams(stringQuery);
      universalTempDataID = queryParams.get("universaltempdata");
    }

    loginClient.loginByDeeplink(loginData.WebID, loginData.Deeplink, universalTempDataID).then(e => {
      if (e.success) {
        if (e.token && e.agentFrontendSettings) {
          const decodedToken = jwtDecode<TokenDTO>(e.token)
          localStorage.setItem("webName", decodedToken.WebName);
          sessionStorage.setItem("jwtToken", e.token);
          sessionStorage.setItem("loginBool", "true");

          dispatch(Session_Reducer_SetToken(e.token));
          dispatch(Session_Reducer_SetFrontendSettings(e.agentFrontendSettings));

          const baseurl = `/auth`;
          let defaultUrl = "auth/availfares";
          switch (e.agentFrontendSettings.startPage) {
            case StartPage.Query:
              defaultUrl = "/availfares";
              break;
            case StartPage.BookingManager:
              defaultUrl = "/bookingmanager";
              break;
          }
          const deeplinkStartpage = queryParams?.get("startpage");
          if (deeplinkStartpage) {
            let isValidQuery = false;
            let resultUrl = "/availfares";
            if (deeplinkStartpage !== null && deeplinkStartpage.length > 0) {
              if (deeplinkStartpage === "bookingmanager") {
                resultUrl = `/bookingmanager`;
                isValidQuery = true;
              }
              if (deeplinkStartpage === "bookingdetails") {
                const filekey = queryParams?.get("filekey");
                isValidQuery = true;
                resultUrl = `/bookingdetails/${filekey}`;
              }
              if (deeplinkStartpage === "availfares" || deeplinkStartpage === "faresearch") {
                resultUrl = "/availfares".concat(stringQuery);
                isValidQuery = true;
              }
              if (isValidQuery) {
                navigate(baseurl + resultUrl);
              }
              else {
                navigate(baseurl + defaultUrl);
              }
            }
          }
          else {
            navigate(baseurl + defaultUrl);
          }
        }
        else {
          setErrorMessage("An unexpected error has occured.")
        }
      }
      else {
        setErrorMessage("Login failed. Please check your credentials.")
      }
    })
      .catch(() => {
        setErrorMessage("Login failed. It seems that our application has encountered an issue. Please try again.")
      })
      .finally(() => {
        dispatch(Session_Reducer_HideGeneralWaitScreen());
      })
  }

  return (
    <React.Fragment>
      {LoginFailed &&
        <div className="alert alert-danger mt-2">
          Login failed.
        </div>
      }
    </React.Fragment>
  );
};

interface RouteParams {
  webid: string;
  deeplinkid: string;
}

export default DeepLoginPage;


import {
  faEllipsisStroke,
  faInfoSquare,
  faPlaneArrival,
  faPlaneDeparture,
  faSpinner,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AirlineObjLogo } from "components/Shared/AirlineLogo";
import { DateDisplay } from "components/Shared/DateDisplay";
import React from "react";
import { BookingItemModel } from "WebApiClient";
import BookingMangerTableRowActions from "./BookingManager.Table.Row.Actions";
import { BookingStatusLabel } from "./BookingStatusLabel";

interface Cardprops {
  item: BookingItemModel;
  UpdateBooking: (data: BookingItemModel) => void;
  IsFetching: boolean;
  NotificationsModalOpen: boolean;
  ToggleNotificationModal: () => void;
  ToggleSplitBookingModal: () => void;
  ToggleSetBookingAsDeprecated: () => void;
  ToggleDetailsModal: () => void;
  OnSync: () => void;
  setMapToggle: () => void;
  seatmapModalOpen: boolean;
  serviceModalOpen: boolean;
  serviceToggle: () => void;
  cancelModalOpen: boolean;
  cancelTicketModal: () => void;
  cancelModal: () => void;
  ElementsModal: () => void;
  ticketModal: () => void;
  ToggleFareModal: () => void;
  ToggleBookingCommunicationModal: () => void;
  ConfirmEmailSend: () => void;
  EmailSendModalOpen: boolean;

}
export const BookingItemCard: React.FC<Cardprops> = ({
  item,
  UpdateBooking,
  NotificationsModalOpen,
  ToggleDetailsModal,
  ToggleSplitBookingModal,
  ToggleSetBookingAsDeprecated,
  ToggleNotificationModal,
  OnSync,
  IsFetching,
  ElementsModal,
  cancelModal,
  cancelModalOpen,
  cancelTicketModal,
  seatmapModalOpen,
  serviceModalOpen,
  serviceToggle,
  setMapToggle,
  ticketModal,
  ConfirmEmailSend,
  EmailSendModalOpen,
  ToggleFareModal,
  ToggleBookingCommunicationModal
}) => {
  const generalInfo = item.latestVersion!.generalInfo;
  const {
    bookingDate,
    firstDepartureDate,
    totalTax,
    bookingId,
    bookingStatus,
    bookingType,
    bookingUTC,
    bookingVersion,
    branch,
    branchgroup,
    cartId,
    crs,
    destination,
    distributionChannel,
    externalReference,
    filekey,
    lastArrivalDate,
    mainPassengerFirstName,
    mainPassengerLastName,
    origin,
    platingCarrier,
    responsibleAgent,
    source,
    statusCode,
    ticketMethod,
    ticketTimeLimit,
    ticketingDate,
    totalPrice,

  } = generalInfo;
  const { gds, terminalPCC } = crs || {};
  return (
    <div className="row p-1">
      <div className="col-12 col-md-8">
        <div className="card">
          <div className="card-body pt-0 pb-0">
            <div className="row">
              <div className="col-12 bb-1 mb-1">
                <div className="row">
                  <div className="col fare__carrier_price">
                    <span className="font-weight-bold">
                      {IsFetching && <FontAwesomeIcon icon={faSpinner} spin />}
                      {filekey}
                    </span>{" "}
                    <br></br>
                    <BookingStatusLabel Status={bookingStatus} />
                  </div>
                  <div className="col">
                    <div className="row">
                      <div className="col-12">
                        <FontAwesomeIcon icon={faUser} />{" "}
                        {mainPassengerLastName}
                      </div>
                    </div>
                    {ticketingDate && (
                      <div className="col-12 fw-bold">
                        Ticketed: <DateDisplay date={ticketingDate} />
                      </div>
                    )}
                  </div>
                  <div className="col">
                    <div className="float-end">
                      <AirlineObjLogo airline={platingCarrier} />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col fare__carrier_price">
                    <div className="price"><SharedPriceDisplay price={totalPrice} /> <span className="float-end text-muted" style={{ fontSize: "0.5em" }}></span></div>
                    <div className="tax">Total price incl. <span className="text-nowrap"><SharedPriceDisplay price={totalTax} /> Taxes</span></div>
                </div> */}
              <div className="col-12">
                <div className="row">
                  <div className="col">
                    <img
                      src={`/images/flags/${origin.countryCode.toLowerCase()}.png`}
                      alt="airport"
                    />{" "}
                    <span className="fw-bold">{origin.iata}</span>{" "}
                    <FontAwesomeIcon icon={faPlaneDeparture} />{" "}
                    <DateDisplay date={firstDepartureDate} />
                  </div>
                  <div className="col text-center">
                    <FontAwesomeIcon icon={faEllipsisStroke} />
                  </div>
                  <div className="col">
                    <img
                      src={`/images/flags/${destination.countryCode.toLowerCase()}.png`}
                      alt="airport"
                    />{" "}
                    <span className="fw-bold">{destination.iata}</span>{" "}
                    <FontAwesomeIcon icon={faPlaneArrival} />{" "}
                    <DateDisplay date={lastArrivalDate} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <div className="btn-group float-end">
              <button
                onClick={ToggleDetailsModal}
                className="btn btn-sm me-2 btn-outline"
              >
                <FontAwesomeIcon icon={faInfoSquare} /> Details
              </button>
              <BookingMangerTableRowActions
                Booking={item}
                OnUpdateBooking={UpdateBooking}
                ToggleSplitBookingModal={ToggleSplitBookingModal}
                ToggleSetBookingAsDeprecated={ToggleSetBookingAsDeprecated}
                NotificationsModalOpen={NotificationsModalOpen}
                ToggleNotificationModal={ToggleNotificationModal}
                ToggleDetailsModal={ToggleDetailsModal}
                Label="Actions"
                OnSync={OnSync}
                IsFetching={IsFetching}
                setMapToggle={setMapToggle}
                seatmapModalOpen={seatmapModalOpen}
                serviceModalOpen={serviceModalOpen}
                serviceToggle={serviceToggle}
                cancelModalOpen={cancelModalOpen}
                cancelTicketModal={cancelTicketModal}
                cancelModal={cancelModal}
                ElementsModal={ElementsModal}
                ticketModal={ticketModal}
                ToggleFareModal={ToggleFareModal}
                ConfirmEmailSend={ConfirmEmailSend}
                EmailSendModalOpen={EmailSendModalOpen}
                ToggleBookingCommunicationModal={ToggleBookingCommunicationModal}
              ></BookingMangerTableRowActions>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-4">
        <div className="card">
          <div className="card-body pt-2 pb-2">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <span className="fw-bold">Carrier:</span>{" "}
                    {platingCarrier.code} -{" "}
                    <span className="fst-italic">{platingCarrier.hint}</span>
                  </div>
                  <div className="col-12">
                    <span className="font-weight-bold">GDS:</span> {gds}
                  </div>
                  <div className="col-12">
                    <span className="font-weight-bold">PCC:</span> {terminalPCC}
                  </div>
                  <div className="col-12">
                    <span className="font-weight-bold">Booking date:</span>{" "}
                    <DateDisplay date={bookingDate} />
                  </div>
                  <div className="col-12">
                    <span className="font-weight-bold">TTL:</span>{" "}
                    <DateDisplay date={ticketTimeLimit} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import jwtDecode from "jwt-decode";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { State } from "rootExports/rootReducer";

export interface UserInformation {
    UserName: string;
    WebName: string;
    BranchgroupName: string;
    BranchName: string;
    ValidUntil: number;
    Deeplink: string;
    WebID: string;
}
export function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}
interface TokenDTO {
    //User ID
    sub: string;
    //User ID
    WebId: string;
    //API Version
    appversion: string;
    //Unique identifier of token / SessionID
    jti: string;
    // Token issued at (UNIX)
    iat: number;
    //Comma seperated list of roles
    roles: string;
    //Not valid before (UNIX)
    nbf: number;
    //Expires (UNIX)
    exp: number;
    // Issuer of token
    iss: string;
    // Audience
    aud: string;
    UserName: string;
    BranchName: string;
    BranchGroupName: string;
    WebName: string;
    deeplink: string;
}
export function useUserInformation(): UserInformation {
    let userName = "???";
    let webName = "???";
    let branchGroupName = "???";
    let branchName = "???";
    let webid = "???";
    let deeplinkid = "???";
    let validUntil: number = 0;
    const token = useSelector((state: State) => state.Session.JwtToken);
    if (token !== "") {
        const decodedToken = jwtDecode<TokenDTO>(token);
        sessionStorage.setItem("webName", decodedToken.WebName);
        if (decodedToken !== null && decodedToken !== undefined) {
            userName = decodedToken.UserName;
            webName = decodedToken.WebName;
            branchGroupName = decodedToken.BranchGroupName;
            branchName = decodedToken.BranchName;
            validUntil = decodedToken.exp;
            webid = decodedToken.WebId;
            deeplinkid = decodedToken.deeplink
        }

    }

    const result: UserInformation = {
        UserName: userName,
        BranchName: branchName,
        BranchgroupName: branchGroupName,
        WebName: webName,
        ValidUntil: validUntil,
        WebID: webid,
        Deeplink: deeplinkid
    };
    return result;
}
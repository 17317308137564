import { faArrowLeft, faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { BookingMangerFilterRequestData } from "WebApiClient";
// import "./BookingManager.Table.styles.css"

interface BookingManagerTablePagingProps {
  IsFetching: boolean,
  LastSentFilter: BookingMangerFilterRequestData;

  //maxitems
  PageLength: number;
  PageAmount: number;
  CurrentOffset: number;
  TotalBookings: number;
  OnSubmit: (filter: BookingMangerFilterRequestData) => void
  OnRefresh: () => void
}

export const ShoppingCartViewInput: React.FC<{ ShoppingCartView: boolean, Toggle: () => void }> = props => {
  function ToggleShoppingCartView() {
    props.Toggle();

  }

  return <div className="switch switch-lg ml-2" onClick={() => ToggleShoppingCartView()}>
    <input
      type="checkbox"
      className="switch"
      checked={props.ShoppingCartView}
      readOnly={true}
    />
    <label>Group by Shopping cart</label>
  </div>
}
export const BookingManagerTablePaging: React.FC<BookingManagerTablePagingProps> = props => {

  const IsFetching = props.IsFetching
  const LastSentFilter = props.LastSentFilter;


  const PageLength = props.PageLength;
  const PageAmount = props.PageAmount;
  const CurrentOffset = props.CurrentOffset

  const TotalBookings = props.TotalBookings;

  function UpdateFilter(filter: BookingMangerFilterRequestData) {
    props.OnSubmit(filter);
  }



  function OnPageChange(offset: number) {
    const updataedFilter: BookingMangerFilterRequestData = { ...LastSentFilter, itemsOffset: offset };
    UpdateFilter(updataedFilter);
  }



  const currentPage = ((+CurrentOffset / +PageLength) + 1);

  function PageSelect(): JSX.Element {
    let options: JSX.Element[] = [];
    let countKey = 0;
    const maxAmount = 0;
    //Appending pages before current
    let countMaxBefore = 0;
    {
      for (let i = currentPage - 1; i >= 1; i--) {
        if ((countMaxBefore < maxAmount) && (i !== 1)) {

          const val = ((i - 1) * PageLength);
          options.push(
            <button className="btn btn-outline-secondary" disabled={IsFetching} onClick={() => { OnPageChange(val) }} key={countKey}>{i}</button>
          )
        }
        countKey++;
        countMaxBefore++;
      }
    }
    if (countMaxBefore > maxAmount) {
      options.push(<button className="btn btn-outline-secondary" key={countKey}>...</button>)
      countKey++;
    }
    {
      CurrentOffset !== 0 &&
        options.push(<button className="btn btn-outline-secondary" disabled={IsFetching} onClick={() => { OnPageChange(0) }} key={countKey}>1</button>)
      countKey++;
    }

    options.reverse()
    options.push(<button className="btn btn-secondary" key={countKey}>{currentPage}</button>)
    countKey++;
    let countMaxAfter = 0;
    //Appending pages after current
    {
      for (let i = currentPage + 1; i <= (+PageAmount - 1); i++) {
        if ((countMaxAfter < maxAmount)) {

          const val = ((i - 1) * PageLength);
          options.push(<button className="btn btn-outline-secondary" disabled={IsFetching} onClick={() => { OnPageChange(val) }} key={countKey}>{i}</button>

          )
        }
        countMaxAfter++;
        countKey++;
      }

    }
    if (countMaxAfter > maxAmount) {
      options.push(<button disabled={IsFetching} className="btn btn-outline-secondary" key={countKey}>...</button>)
      countKey++;

    }
    if ((CurrentOffset !== ((PageAmount - 1) * PageLength)) && +TotalBookings > 0) {
      options.push(<button className="btn btn-outline-secondary" disabled={IsFetching} onClick={() => { OnPageChange(((PageAmount - 1) * PageLength)) }} key={countKey}>{PageAmount}</button>)
      countKey++;
    }
    function OnPageLengthChange(pageLength: number) {
      const updataedFilter: BookingMangerFilterRequestData = { ...LastSentFilter, maxItems: pageLength, itemsOffset: 0 };
      props.OnSubmit(updataedFilter);
    }
    return <div>
      <div className="btn-toolbar">


        <div className="input-group me-2">

          {/* <label>Page length</label> */}
          <select className="form-select" value={props.PageLength} onChange={(e) => OnPageLengthChange(e.target.value as any as number)}>
            <option value={1} >1</option>
            <option value={10} >10</option>
            <option value={20} >20</option>
            <option value={50} >50</option>
            <option value={100} >100</option>
            <option value={250} >250</option>
          </select>
        </div>
        <div className="btn-group">
          <button className="btn btn-primary me-1" disabled={(CurrentOffset === 0) || IsFetching} onClick={() => { OnPageChange(+CurrentOffset - +PageLength) }}><FontAwesomeIcon icon={faArrowLeft} /></button>
          <button className="btn btn-primary me-1" disabled={((CurrentOffset === ((PageAmount - 1) * PageLength)) || IsFetching || +TotalBookings === 0)} onClick={() => { OnPageChange(+CurrentOffset + +PageLength) }}><FontAwesomeIcon icon={faArrowRight} /></button>
          {options}

        </div>

      </div>
    </div>
  }








  return PageSelect();


}
export const BookingManagerAmountInfo: React.FC<{ CurrentOffset: number, CurrentBookings: number, TotalBookings: number }> = props => {
  const CurrentOffset = props.CurrentOffset;
  const CurrentBookings = props.CurrentBookings;
  const TotalBookings = props.TotalBookings;

  function GetDisplayedBookingsCount(): number {
    const displayedCount = +CurrentOffset + +CurrentBookings;
    return displayedCount > TotalBookings ? TotalBookings : displayedCount;
  }

  return <span>
    {CurrentOffset + 1} - {GetDisplayedBookingsCount()} / {TotalBookings}
  </span>
}
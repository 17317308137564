import { ErrorDisplay } from "components/Shared/APIMetaDataDisplay";
import { WaitingCard } from "components/Shared/WaitingCard";
import { useBookingClient } from "hooks/useHttpClient";
import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Session_Reducer_PushTransaction } from 'rootExports/SessionReducer';
import { BaseApiResponse, FareSourceEnumModel, ImportBookingModel } from "WebApiClient";

interface ImportBookingModalProps {
  Toggle: () => void
  IsOpen: boolean
  refreshTable?:any
}
const ImportBookingModal: React.FC<ImportBookingModalProps> = props => {
  const bookClient = useBookingClient();
  const [fetching, setFetching] = useState(false);
  const [response, setResponse] = useState<BaseApiResponse | undefined>(undefined);
  const dispatch = useDispatch();

  const defaultImportModel: ImportBookingModel = { branch: "", branchGroup: "", corporateCode: "", crsGUID: "", crsReceivedFrom: "", crsTerminalCountry: "", crsTerminalPCC: "", crsUser: "", distributionchannel: "", equivalentCurrencyCode: "", extensions: "", externalReference: "", fareSelectionCurrency: "", filekey: "", gds: "", responsibleAgent: "", source: FareSourceEnumModel.GDSSource }
  const [importModel, setImportModel] = useState<ImportBookingModel>(defaultImportModel);


  const [errorOccured, setErrorOccured] = useState(false);

  function OnSubmit(model: ImportBookingModel) {
    setFetching(true);
    bookClient.importBooking(model)
      .then(response => {
        setResponse(response);
        dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
        if (response.responseMetaData.errorOccured) {
          setErrorOccured(true)
        }
        else {
          setErrorOccured(false);
        }
      })
      .catch(() => {
        setErrorOccured(true);
      })
      .finally(() => {
        setFetching(false);
        props.refreshTable()
      })
  }

  return (
    <React.Fragment>

      <Modal size="lg" centered toggle={props.Toggle} isOpen={props.IsOpen}>
        <ModalHeader toggle={props.Toggle}>Import booking</ModalHeader>
        <ModalBody>
          <div className="row">

            {fetching &&
              <div className="col-12">
                <WaitingCard />
              </div>
            }
            {!fetching &&
              <React.Fragment>
                {errorOccured &&
                  <ErrorDisplay data={response?.responseMetaData} />
                }

                {(response && !response.responseMetaData.errorOccured) &&

                  <Fragment>
                    <div className="col-12 mb-2">
                      <Alert color="success">
                        Booking imported.
                      </Alert>
                    </div>
                  </Fragment>
                }
                <div className="col-12 mb-2">
                  <ImportBookingInput OnSubmit={OnSubmit}  setImportModel={setImportModel} importModel={importModel}/>
                </div>

              </React.Fragment>
            }

          </div>

        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={()=> setImportModel(defaultImportModel)}>clear</Button>
          <Button color="primary" onClick={props.Toggle}>close</Button>
        </ModalFooter>
      </Modal>

    </React.Fragment>
  );
}
const ImportBookingInput: React.FC<{ OnSubmit: (model: ImportBookingModel) => void, setImportModel:any, importModel:any}> = props => {
  const {setImportModel,importModel} = props;
  const { branch, branchGroup, corporateCode, crsGUID, crsReceivedFrom, crsTerminalCountry, crsTerminalPCC, crsUser, distributionchannel, equivalentCurrencyCode, extensions, externalReference, fareSelectionCurrency, filekey, gds, responsibleAgent, source } = importModel;

  return <div className="card card-primary">
    <div className="card-header card-header-primary">Import new Booking</div>
    <div className="card-body card-body-primary">
      <div className="form-group mb-1">
        <div className="pb-1">Filekey</div>
        <input value={filekey} onChange={(e) => { setImportModel({ ...importModel, filekey: e.target.value }) }} className="form-control" />
      </div>
      <div className="row">
        <div className="col-12">
          <div className="row">

            <div className="col-4">
              <div className="form-group mb-1">
                <div className="pb-1">Currency code (equivalent)</div>
                <input value={fareSelectionCurrency} onChange={(e) => { setImportModel({ ...importModel, fareSelectionCurrency: e.target.value }) }} className="form-control" />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group mb-1">
                <div className="pb-1">Currency code (fare selection)</div>
                <input value={equivalentCurrencyCode} onChange={(e) => { setImportModel({ ...importModel, equivalentCurrencyCode: e.target.value }) }} className="form-control" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">



          <h5>CRS</h5>
          <div className="form-group mb-1">
            <div className="pb-1">Source</div>
            <select value={source} className="form-control" onChange={(e) => { setImportModel({ ...importModel, source: e.target.value as any as FareSourceEnumModel }) }}>
              <option value={FareSourceEnumModel.GDSSource}>GDS</option>
              <option value={FareSourceEnumModel.IBESource}>IBE</option>
              <option value={FareSourceEnumModel.CharterSource}>Charter</option>
              <option value={FareSourceEnumModel.NFESource}>NFE</option>
              <option value={FareSourceEnumModel.VayantSource}>Vayant</option>
              <option value={FareSourceEnumModel.WebSource}>Webfare</option>
            </select>

          </div>
          <div className="form-group mb-1">
            <div className="pb-1">GDS</div>
            <input value={gds} onChange={(e) => { setImportModel({ ...importModel, gds: e.target.value }) }} className="form-control" />
          </div>
          <div className="form-group mb-1">
            <div className="pb-1">GUID</div>
            <input value={crsGUID} onChange={(e) => { setImportModel({ ...importModel, crsGUID: e.target.value }) }} className="form-control" />
          </div>
          <div className="form-group mb-1">
            <div className="pb-1">Received from</div>
            <input value={crsReceivedFrom} onChange={(e) => { setImportModel({ ...importModel, crsReceivedFrom: e.target.value }) }} className="form-control" />
          </div>
          <div className="form-group mb-1">
            <div className="pb-1">PCC</div>
            <input value={crsTerminalPCC} onChange={(e) => { setImportModel({ ...importModel, crsTerminalPCC: e.target.value }) }} className="form-control" />
          </div>
          <div className="form-group mb-1">
            <div className="pb-1">User</div>
            <input value={crsUser} onChange={(e) => { setImportModel({ ...importModel, crsUser: e.target.value }) }} className="form-control" />
          </div>
          <div className="form-group mb-1">
            <div className="pb-1">Terminal country</div>
            <input value={crsTerminalCountry} onChange={(e) => { setImportModel({ ...importModel, crsTerminalCountry: e.target.value }) }} className="form-control" />
          </div>
        </div>
        <div className="col-6">
          <h5>Additional information</h5>
          <div className="form-group mb-1">
            <div className="pb-1">Branch</div>
            <input value={branch} onChange={(e) => { setImportModel({ ...importModel, branch: e.target.value }) }} className="form-control" />
          </div>
          <div className="form-group mb-1">
            <div className="pb-1">Branchgroup</div>
            <input value={branchGroup} onChange={(e) => { setImportModel({ ...importModel, branchGroup: e.target.value }) }} className="form-control" />
          </div>
          <div className="form-group mb-1">
            <div className="pb-1">Responsible Agent</div>
            <input value={responsibleAgent} onChange={(e) => { setImportModel({ ...importModel, responsibleAgent: e.target.value }) }} className="form-control" />
          </div>
          <div className="form-group mb-1">
            <div className="pb-1">Corporate code</div>
            <input value={corporateCode} onChange={(e) => { setImportModel({ ...importModel, corporateCode: e.target.value }) }} className="form-control" />
          </div>
          <div className="form-group mb-1">
            <div className="pb-1">Distribution channel</div>
            <input value={distributionchannel} onChange={(e) => { setImportModel({ ...importModel, distributionchannel: e.target.value }) }} className="form-control" />
          </div>
          <div className="form-group mb-1">
            <div className="pb-1">Extensions</div>
            <input value={extensions} onChange={(e) => { setImportModel({ ...importModel, extensions: e.target.value }) }} className="form-control" />
          </div>
          <div className="form-group mb-1">
            <div className="pb-1">External reference</div>
            <input value={externalReference} onChange={(e) => { setImportModel({ ...importModel, externalReference: e.target.value }) }} className="form-control" />
          </div>
        </div>
        <div className="col-12 mt-2">
          <div className="d-grid">
            <button className="btn btn-primary" onClick={() => { props.OnSubmit(importModel) }}>Import</button>
          </div>
        </div>
      </div>
    </div>
  </div>

}













export default ImportBookingModal




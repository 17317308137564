import { faKey, faUser, faGlobe } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TokenDTO } from "components/General/types/SessionTypes";
import { AgentApiURL, Environment } from "Constants";
import { useLoginClient } from "hooks/useHttpClient";
import { useSessionHandler } from "hooks/useSessionHandler";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { Alert } from "reactstrap";
import { loginBool_Reducer } from "rootExports/LoginReducer";
import { State } from "rootExports/rootReducer";
import {
  Session_Reducer_HideGeneralWaitScreen,
  Session_Reducer_SetFrontendSettings,
  Session_Reducer_SetToken,
  Session_Reducer_ShowGeneralWaitScreen,
  Session_Reducer_isAdmin,
} from "rootExports/SessionReducer";
import { LoginModel, StartPage } from "WebApiClient";

const LoginPage = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const IsLoggedIn = useSelector((state: State) => state.Session.isLoggedIn);
  const loginClient = useLoginClient();
  const [userName, changeUserName] = useState("");
  const [password, changePassword] = useState("");
  const [isWebName, changeWebName] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const { routeWebname } = useParams();
  const LoginFailed = errorMessage !== "";
  const localWebName = localStorage.getItem('webName');
  const [webName, setWebName] = useState<any>(null);
  const Logo = "/images/default/hh-logo.png"

  useEffect(() => {
    if (routeWebname) {
      setWebName(routeWebname);
    } else if (localWebName) {
      setWebName(localWebName);
    } else {
      changeWebName(true);
    }
  }, [routeWebname, localWebName]);

  useEffect(() => {
    if (webName && !isWebName) {
      const handler = setTimeout(() => {
        loginClient.webNameExists(webName).then((res: any) => {
          setWebExists(res.exists);
          setLogoId(res.logoId);
        });
      }, 500); // Adjust the delay as needed

      return () => clearTimeout(handler); // Cleanup function to clear previous timeouts
    }

    document.title = "Login";
  }, [webName, isWebName]);


  const [webExists, setWebExists] = useState(true);
  const [logoId, setLogoId] = useState("");


  function Login(e: LoginModel) {
    dispatch(Session_Reducer_ShowGeneralWaitScreen());
    loginClient
      .loginByCredentials(e)
      .then(async (response) => {
        if (response.success) {
          if (response.isAdmin) {
            dispatch(Session_Reducer_isAdmin(response.isAdmin));
          }

          if (response.token && response.agentFrontendSettings) {
            const decodedToken = jwtDecode<TokenDTO>(response.token);
            localStorage.setItem("webName", decodedToken.WebName);
            // 🔹 (Better Approach) Store token in sessionStorage or memory

            localStorage.setItem("webName", decodedToken.WebName);
            sessionStorage.setItem("jwtToken", response.token);
            // 🔹 Use API to fetch user details instead of decoding JWT manually
            dispatch(Session_Reducer_SetFrontendSettings(response.agentFrontendSettings));

            // ✅ Wait for Redux state updates to complete before navigation
            await Promise.all([
              dispatch(Session_Reducer_SetToken(response.token)),
              dispatch(Session_Reducer_isAdmin(response.isAdmin)),
            ]);

            // ✅ Navigate based on user role
            switch (response.agentFrontendSettings.startPage) {
              case StartPage.BookingManager:
                navigate(`/auth/bookingmanager`, { replace: true });
                break;
              case StartPage.Query:
                navigate(`/auth/availfares`, { replace: true });
                break;
              default:
                navigate(`/dashboard`, { replace: true });
            }
          } else {
            setErrorMessage("An unexpected error has occurred.");
          }
        } else {
          setErrorMessage(response?.errorMessages?.[0] || "Login failed. Please check your credentials.");
        }
      })
      .catch(() => {
        setErrorMessage("Login failed due to a server issue. Please try again.");
      })
      .finally(() => {
        dispatch(Session_Reducer_HideGeneralWaitScreen());
      });
  }

  return (
    <React.Fragment>
      {!IsLoggedIn && webExists && (
        <div id="screen-login">
          <div id="center-screen-login">
            <form
              onSubmit={(e: React.FormEvent) => {
                e.preventDefault();
                loginClient.webNameExists(webName).then((res: any) => {
                  setWebExists(res.exists);
                  setLogoId(res.logoId);
                });
                dispatch(loginBool_Reducer(true));
                sessionStorage.setItem("loginBool", JSON.stringify(true));
                Login({
                  username: userName,
                  password: password,
                  webname: webName,
                  support: false,
                  supportname: undefined,
                  universalTempDataID: undefined,
                });
              }}
            >
              <div className="container">
                <input type="hidden" />
                <div className="">
                  <div className="container pt-4">
                    {!isWebName ? (

                      <div className="row justify-content-center">
                        <div
                          className="col-12 col-md-7 col-lg-6 col-xl-3 mb-4 text-center"
                          id="welcome__logo"
                        >
                  
                          <img
                            src={
                              `${AgentApiURL}/publicservices/images/logo/${logoId}`
                            }
                            style={{ maxWidth: 180 }}
                            className="img-fluid mb-2 companyLogo"
                            alt="company logo"
                          ></img>
                        </div>
                      </div>
                    ) :
                      <div className="row justify-content-center">
                        <div
                          className="col-12 col-md-7 col-lg-6 col-xl-3 mb-4 text-center"
                          id="welcome__logo"
                        >
                          {/* <img src={Logo} alt="HitchHiker Logo" /> */}
                          <img
                            src={Logo}
                            style={{ maxWidth: 180 }}
                            className="img-fluid mb-2 companyLogo"
                            alt="company logo"
                          ></img>
                        </div>
                      </div>
                    }
                    <div className="row justify-content-center">
                      <div className="col-12 col-md-7 col-lg-6 col-xl-3">
                        <div className="card" id="card-login">
                          {/* <div className="card-header card-header-primary text-left">
                            LOGIN to Web {webName}
                          </div> */}
                          <div className="card-body card-body-primary">
                            <div className="input-group mb-1">
                              <span
                                className="input-group-text"
                                id="addon__Login_Username"
                              >
                                <FontAwesomeIcon icon={faUser} />
                              </span>
                              <input value={userName}
                                onChange={(e) => changeUserName(e.target.value)}
                                type="text"
                                className="form-control"
                                id="input__Login_Username"
                                placeholder="Username"
                                required
                              />
                            </div>
                            <div className="input-group mb-1">
                              <span
                                className="input-group-text"
                                id="addon__Login_Password"
                              >
                                <FontAwesomeIcon icon={faKey} />
                              </span>
                              <input
                                value={password}
                                onChange={(e) => changePassword(e.target.value)}
                                type="password"
                                className={`form-control ${LoginFailed ? "is-invalid" : ""
                                  }`}
                                id="input__Login_Password"
                                placeholder="Password"
                              />
                            </div>
                            {isWebName && (
                              <div className="input-group mb-1">
                                <span className="input-group-text" id="addon__webNameHolder">
                                  <FontAwesomeIcon icon={faGlobe} />
                                </span>
                                <input
                                  value={webName}
                                  onChange={(e) => setWebName(e.target.value)}
                                  type="text"
                                  className="form-control"
                                  id="input_WebName"
                                  placeholder="Web Name"
                                  required
                                />
                              </div>
                            )}
                            {LoginFailed && (
                              <div className="alert alert-danger mt-2">
                                {errorMessage}
                              </div>
                            )}
                            <div className="text-center">
                              <button
                                type="submit"
                                id="login__Submit"
                                className="btn btn-primary w-50"
                              >
                                Login
                              </button>
                            </div>
                          </div>
                          {/* <p className="small">Environment:{Environment}</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {IsLoggedIn && (
        <div className="container">
          <div className="row align-middle">
            <div className="col"></div>
            <div className="col-auto">
              <Alert className="fw-bold">You are already logged in.</Alert>
            </div>
            <div className="col"></div>
          </div>
        </div>
      )}
      {!webExists && (
        <div className="container xl-wrapper">
          <div className="vertical-center">
            <div className="row">
              <div className="col-12 content-wrapper">
                <div className="card" id="card-error">
                  <div className="card-header card-header-primary text-center text-danger font-weight-bold">
                    <i className="fas fa-exclamation-triangle pr-2"></i> ERROR -
                    Web Not found.
                    <i className="fas fa-exclamation-triangle pl-2"></i>
                  </div>
                  <div className="card-body card-body-primary text-center">
                    The Web <b>{webName}</b> does not exist. Please refresh the page.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default LoginPage;

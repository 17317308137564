import { faSort, faSortDown, faSortUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  AgentShoppingCartSort,
  AscDescEnum,
  BaseApiResponse,
  BookingIdentifier,
  BookingItemModel,
  BookingMangerFilterRequestData,
  ShoppingCartSortModifier,
  UpdatedBookingApiResponse,
} from "WebApiClient";
import { ShoppingCartModel } from "../types/BookingManagerTypes";
// import { FieldOrdered, GetSortOrder, OrderBookings } from "./BookingManager.Table.GetSortOrder";
import BookingMangerTablerow, {
  EmptyBookingMangerTableRow,
} from "./BookingManager.Table.Row";
import { faRotateExclamation } from "@fortawesome/pro-regular-svg-icons";
import { useBookingClient } from "hooks/useHttpClient";
import { Session_Reducer_PushTransaction } from "rootExports/SessionReducer";
import { useDispatch, useSelector } from "react-redux";
import BookingManagerErrorModal from "./SyncBookingModal";
import { State } from "rootExports/rootReducer";

// import "./BookingManager.Table.styles.css"

interface BookingMangerTableProps {
  IsFetching: boolean;
  GroupByShoppingCart: boolean;
  UpdateBooking: (data: BookingItemModel, bookingID: string) => void;
  OnSubmit: (filter: BookingMangerFilterRequestData) => void;
  PageAmount: number;
  pageLength: number;
  filter: BookingMangerFilterRequestData;
  Bookings: BookingItemModel[];
  HideTestBookings: boolean;
  autoRefresh?: string;
  cardView: boolean;
  isFilterApplied: boolean;
  GetBookingsWithCurrentFilter: () => void;
}

const BookingMangerTable: React.FC<BookingMangerTableProps> = (props) => {
  const filter = props.filter;
  const bookClient = useBookingClient();
  const [rowFetching, setRowIsFetching] = useState(true);
  const [syncResponse, setSyncResponse] = useState<BaseApiResponse | undefined>(undefined);
  const [syncErrorOccured, setSyncErrorOccured] = useState(false);
  const dispatch = useDispatch();
  const Bookings = props.HideTestBookings ? props.Bookings.filter((e) => e.latestVersion.generalInfo.filekey.toLocaleLowerCase() !== "testkey") : props.Bookings;
  const [isLoading, setIsLoading] = useState(false);
  const [bookingIdentifier10, setBookingIdentifier10] = useState<BookingItemModel[]>([]);
  const [errorIdentifier10, setErrorIdentifier10] = useState<BookingItemModel[]>([]);
  const [bookingResponse10, setBookingResponse10] = useState<UpdatedBookingApiResponse[]>([]);
  const [rowSelector, setRowSelector] = useState("");

  const syncAll = async () => {
    try {
      // Set loading to true when starting sync
      setIsLoading(true);
      setRowIsFetching(true);

      // Determine bookings to sync based on the limit
      const bookingsToSync = Bookings.slice(0, Math.min(Bookings.length, Bookings.length));
      console.log("Bookings to Sync:", bookingsToSync); // Check the bookings to sync
      setBookingIdentifier10([]);
      setTimeout(() => {
        setBookingIdentifier10(bookingsToSync);
      }, 0);

      for (const booking of bookingsToSync) {
        const bookingIdentifier = booking.latestVersion?.bookingIdentifier;
        // Skip bookings without a valid latestVersion or bookingIdentifier
        if (!bookingIdentifier) {
          console.warn(`Skipping booking due to missing identifier:`, booking);
          continue;
        }

        try {
          // Sync each booking individually
          const response = await bookClient.syncBooking(bookingIdentifier);
          // Update response
          setBookingResponse10([]);
          setTimeout(() => {
            setBookingResponse10((prevResponses) => [...prevResponses, response]);
          }, 0);
          setSyncResponse(response);
          dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
          // Update booking and error state
          if (response.responseMetaData.errorOccured) {
            setSyncErrorOccured(true);
            setErrorIdentifier10((prevResponses) => [...prevResponses, booking])

          } else {
            setSyncErrorOccured(false);
            props.UpdateBooking(response.updatedBooking!, response.updatedBooking?.latestVersion.bookingIdentifier.bookingId);
          }
        } catch (error) {
          console.error("Error syncing booking:", bookingIdentifier, error); // Log the error
          setSyncErrorOccured(true);
        }
      }
    } catch (error) {
      console.error("Unexpected error during sync:", error);
      setSyncErrorOccured(true);
    } finally {
      setRowIsFetching(false);
      setIsLoading(false); // Set loading to false after all operations
    }
  };

  const isFetching = props.IsFetching;
  const [bool, setBool] = useState(false);
  function SetOrdering(field: AgentShoppingCartSort) {
    if (!isFetching) {
      const ordered: ShoppingCartSortModifier | undefined =
        filter.sortModifiers!.find((e) => e.field === field);
      let newModifiers = [...filter.sortModifiers!];
      if (ordered === undefined) {
        newModifiers.push({ field: field, ascDesc: AscDescEnum.Ascending });
      } else {
        let index = newModifiers.findIndex((e) => e.field === field);
        if (ordered.ascDesc === AscDescEnum.Ascending) {
          newModifiers[index] = {
            ...newModifiers[index],
            ascDesc: AscDescEnum.Descending,
          };
        } else {
          newModifiers.splice(index, 1);
        }
      }
      let newFilter: BookingMangerFilterRequestData = {
        ...filter,
        sortModifiers: newModifiers,
      };
      props.OnSubmit(newFilter);
    }
  }

  function RenderShoppingCartRow(
    data: ShoppingCartModel,
    cartIndex: number
  ): JSX.Element {
    console.log("data---", data.Bookings);
    return (
      <React.Fragment>
        <tr>
          <td className="bg-white text-center" colSpan={11}>
            <span className="text-muted">{data.CartID}</span>{" "}
          </td>
        </tr>
        {data.Bookings.map((booking, index) => (
          <tr key={`bookingrow_${cartIndex}_${index}`}>
            <BookingMangerTablerow
              Booking={booking}
              CardView={false}
              UpdateBooking={(e) => {
                props.UpdateBooking(
                  e,
                  e.latestVersion.bookingIdentifier.bookingId
                );
              }}
              Fetching={props.IsFetching}
              IndexRow={index}
              showBool={setBool}
              bool={bool}
              bookingIdentifier10={bookingIdentifier10}
              bookingResponse10={bookingResponse10}
              pageLength={props.pageLength}
              errorIdentifier10={errorIdentifier10}
              GetBookingsWithCurrentFilter={props.GetBookingsWithCurrentFilter}
            />
          </tr>
        ))}
        <tr className="bg-white" style={{ height: "30px" }}>
          <td colSpan={11}></td>
        </tr>
      </React.Fragment>
    );
  }

  function RenderShoppingCard(
    data: ShoppingCartModel,
    cartIndex: number
  ): JSX.Element {
    return (
      <React.Fragment>
        {data.Bookings.map((booking, index) => (
          <BookingMangerTablerow
            Booking={booking}
            CardView={false}
            UpdateBooking={(e) => {
              props.UpdateBooking(
                e,
                e.latestVersion.bookingIdentifier.bookingId
              );
            }}
            Fetching={props.IsFetching}
            IndexRow={index}
            showBool={setBool}
            bool={bool}
            pageLength={props.pageLength}
            bookingIdentifier10={bookingIdentifier10}
            bookingResponse10={bookingResponse10}
            errorIdentifier10={errorIdentifier10}
            GetBookingsWithCurrentFilter={props.GetBookingsWithCurrentFilter}
          />
        ))}
      </React.Fragment>
    );
  }

  function RenderBookingList(): JSX.Element {
    const ordered = Bookings;

    const selectedRow = (bookingKey: any) => {
      setRowSelector(bookingKey);
    }

    if (props.GroupByShoppingCart) {
      let shoppingcarts: ShoppingCartModel[] = [];
      ordered.forEach((booking) => {
        const cartID = booking.latestVersion!.generalInfo!.cartId!;
        const indexOf = shoppingcarts.findIndex((e) => e.CartID === cartID);
        if (indexOf === -1) {
          let cart: ShoppingCartModel = { CartID: cartID, Bookings: [booking] };
          shoppingcarts.push(cart);
        } else {
          shoppingcarts[indexOf].Bookings.push(booking);
        }
      });


      return (
        <React.Fragment>
          {shoppingcarts.map((cart, index) => (
            <tbody
              style={{ borderBottom: "solid black 0.2em" }}
              key={`sc_${index}`}
            >
              {props.Bookings.length === 0 && props.IsFetching && (
                <tr>
                  <EmptyBookingMangerTableRow></EmptyBookingMangerTableRow>
                </tr>
              )}
              {RenderShoppingCartRow(cart, index)}
            </tbody>
          ))}

          <BookingManagerErrorModal
            Close={() => {
              setSyncResponse(undefined);
              setSyncErrorOccured(false);
            }}
            IsOpen={syncErrorOccured}
            data={syncResponse?.responseMetaData}
          />

        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <tbody>
            {props.Bookings.length === 0 && props.IsFetching && (
              <tr>
                <EmptyBookingMangerTableRow></EmptyBookingMangerTableRow>
              </tr>
            )}
            {ordered.map((booking, index) => (
              <tr key={`bookingrow_${index}`} className={rowSelector == booking?.latestVersion?.generalInfo?.filekey ? 'selectedBookingManagerTableRow' : ''} onClick={() => selectedRow(booking?.latestVersion?.generalInfo?.filekey)}>
                <BookingMangerTablerow
                  CardView={false}
                  Booking={booking}
                  UpdateBooking={(e) => {
                    props.UpdateBooking(
                      e,
                      e.latestVersion.bookingIdentifier.bookingId
                    );
                  }}
                  Fetching={props.IsFetching}
                  IndexRow={index}
                  showBool={setBool}
                  bool={bool}
                  pageLength={props.pageLength}
                  bookingIdentifier10={bookingIdentifier10}
                  bookingResponse10={bookingResponse10}
                  errorIdentifier10={errorIdentifier10}
                  GetBookingsWithCurrentFilter={props.GetBookingsWithCurrentFilter}
                />
              </tr>
            ))}
          </tbody>
        </React.Fragment>
      );
    }
  }

  function RenderBookingCard(): JSX.Element {
    const ordered = Bookings;
    if (props.GroupByShoppingCart) {
      let shoppingcarts: ShoppingCartModel[] = [];
      ordered.forEach((booking) => {
        const cartID = booking.latestVersion!.generalInfo!.cartId!;
        const indexOf = shoppingcarts.findIndex((e) => e.CartID === cartID);
        if (indexOf === -1) {
          let cart: ShoppingCartModel = { CartID: cartID, Bookings: [booking] };
          shoppingcarts.push(cart);
        } else {
          shoppingcarts[indexOf].Bookings.push(booking);
        }
      });

      return (
        <React.Fragment>
          {shoppingcarts.map((cart, index) => (
            <div key={"cart" + index}>
              {props.Bookings.length === 0 && props.IsFetching && (
                <>
                  <EmptyBookingMangerTableRow></EmptyBookingMangerTableRow>
                </>
              )}
              {RenderShoppingCard(cart, index)}
            </div>
          ))}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <>
            {props.Bookings.length === 0 && props.IsFetching && <>{null}</>}
            {ordered.map((booking, index) => (
              <div key={"card" + index}>
                <BookingMangerTablerow
                  CardView={true}
                  Booking={booking}
                  UpdateBooking={(e) => {
                    props.UpdateBooking(
                      e,
                      e.latestVersion.bookingIdentifier.bookingId
                    );
                  }}
                  Fetching={props.IsFetching}
                  IndexRow={index}
                  showBool={setBool}
                  bool={bool}
                  bookingIdentifier10={bookingIdentifier10}
                  pageLength={props.pageLength}
                  bookingResponse10={bookingResponse10}
                  errorIdentifier10={errorIdentifier10}
                  GetBookingsWithCurrentFilter={props.GetBookingsWithCurrentFilter}
                />
              </div>
            ))}
          </>
        </React.Fragment>
      );
    }
  }

  function GetArrowIcon(field: AgentShoppingCartSort): JSX.Element {
    const ordered: ShoppingCartSortModifier | undefined =
      filter.sortModifiers!.find((e) => e.field === field);
    let ordericon = faSort;
    let indicator = (
      <span>
        <FontAwesomeIcon icon={ordericon} />
      </span>
    );
    if (ordered !== undefined) {
      const indexOf = filter.sortModifiers!.findIndex((e) => e.field === field);
      ordericon =
        ordered.ascDesc === AscDescEnum.Ascending ? faSortUp : faSortDown;
      indicator = (
        <span>
          <FontAwesomeIcon icon={ordericon} />{" "}
          <span className="badge badge-pill badge-light">{indexOf + 1}</span>
        </span>
      );
    }
    return <span className="float-end">{indicator}</span>;
  }

  return (
    <React.Fragment>
      {!props.cardView ? (
        <div className="">
          <table
            className={`table table-sm table-bordered w-100 table-hover bg-white bMTable ${!props.GroupByShoppingCart ? "table-striped" : ""
              }`}
            id="bookinglist__allbookings"
          >
            <BookingMangerTableHeader
              ClassName={"thead-dark clickme"}
              GetArrowIcon={GetArrowIcon}
              SetOrdering={SetOrdering}
              ShowActions={true}
              ShowSyncHeader={true}
              isFilterApplied={props.isFilterApplied}
              syncAll={syncAll}
              isLoading={isLoading}
            />

            {RenderBookingList()}
          </table>
        </div>
      ) : (
        RenderBookingCard()
      )}
    </React.Fragment>
  );
};



export const BookingMangerTableHeader: React.FC<{
  SetOrdering(sort: AgentShoppingCartSort): void;
  GetArrowIcon(sort: AgentShoppingCartSort): JSX.Element;
  ClassName: string;
  ShowActions: boolean;
  ShowSyncHeader?: boolean;
  elementVisible?: boolean;
  isFilterApplied: boolean;
  syncAll: () => void;
  isLoading?: boolean;
}> = (props) => {
  const { SetOrdering, GetArrowIcon, ClassName, ShowActions, elementVisible, isFilterApplied, syncAll, isLoading } = props;
  var allowedGlobalActions: number[] = useSelector((state: State) => state.Session.FrontendSettings.allowedBookingManagerGlobalFeatures);

  return (
    <thead className={ClassName}>
      {!elementVisible && (
        <tr>
          {props.ShowSyncHeader && (
            <th style={{ width: "1%" }} className="fw-normal">
              {isFilterApplied && allowedGlobalActions.includes(4) ?
                <span className="d-flex justify-content-center flex-column align-items-center text-center">
                  {isLoading ? (
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <>
                      <FontAwesomeIcon title="Refresh PNR" icon={faRotateExclamation} onClick={syncAll} />
                      <small onClick={syncAll}>Sync all</small>
                    </>
                  )}
                </span> : <span>
                  <small>GDS</small>
                  <br />
                  Sync
                </span>
              }
            </th>
          )}
          <th
            className="align-bottom fw-normal"
            onClick={() => SetOrdering(AgentShoppingCartSort.MainTravellerName)}
          >
            <small>Passenger</small>
            <br />
            Last name {GetArrowIcon(AgentShoppingCartSort.MainTravellerName)}
          </th>
          <th className="align-bottom fw-normal">Filekey</th>
          <th
            className="align-bottom fw-normal"
            onClick={() => SetOrdering(AgentShoppingCartSort.PlatingCarrier)}
          >
            Airline {GetArrowIcon(AgentShoppingCartSort.PlatingCarrier)}
          </th>
          <th
            className="align-bottom fw-normal"
            onClick={() => SetOrdering(AgentShoppingCartSort.GDS)}
          >
            <small>Source</small>
            <br />
            GDS {GetArrowIcon(AgentShoppingCartSort.GDS)}
          </th>
          <th
            className="align-bottom fw-normal"
            onClick={() => SetOrdering(AgentShoppingCartSort.PCC)}
          >
            <small>Source</small>
            <br />
            Terminal PCC {GetArrowIcon(AgentShoppingCartSort.PCC)}
          </th>
          <th
            className="align-bottom fw-normal"
            onClick={() => SetOrdering(AgentShoppingCartSort.BookingStatus)}
          >
            <small>Status</small>
            <br />
            Booking {GetArrowIcon(AgentShoppingCartSort.BookingStatus)}
          </th>
          <th
            className="align-bottom fw-normal"
            onClick={() => SetOrdering(AgentShoppingCartSort.BookingDate)}
          >
            <small>Date</small>
            <br />
            Booking {GetArrowIcon(AgentShoppingCartSort.BookingDate)}
          </th>
          <th
            className="align-bottom fw-normal"
            onClick={() =>
              SetOrdering(AgentShoppingCartSort.FirstDepartureDate)
            }
          >
            <small>Date</small>
            <br />
            First departure{" "}
            {GetArrowIcon(AgentShoppingCartSort.FirstDepartureDate)}
          </th>
          <th
            className="align-bottom fw-normal"
            onClick={() => SetOrdering(AgentShoppingCartSort.TicketTimeLimit)}
          >
            <small>Date</small>
            <br />
            TTL {GetArrowIcon(AgentShoppingCartSort.TicketTimeLimit)}
          </th>
          <th
            className="align-bottom fw-normal"
            onClick={() => SetOrdering(AgentShoppingCartSort.TicketingDate)}
          >
            <small>Date</small>
            <br />
            Ticketing {GetArrowIcon(AgentShoppingCartSort.TicketingDate)}
          </th>
          <th
            className="align-bottom fw-normal text-center"
            onClick={() =>
              SetOrdering(AgentShoppingCartSort.NotificationActionRequired)
            }
          >
            Notifications{" "}
            {GetArrowIcon(AgentShoppingCartSort.NotificationActionRequired)}
          </th>
          {ShowActions && (
            <th className="align-bottom fw-normal text-center">Action</th>
          )}
        </tr>
      )}
      {elementVisible && (
        <tr>
          {props.ShowSyncHeader && (
            <th style={{ width: "1%" }}>
              <small>GDS</small>
              <br />
              Sync
            </th>
          )}
          <th className="font-weight-bold">Passenger Last name</th>
          <th className="font-weight-bold">Filekey</th>
          <th className="font-weight-bold">Airline</th>
          <th className="font-weight-bold">Source GDS</th>
          <th className="font-weight-bold">Source Terminal PCC</th>
          <th className="font-weight-bold">Status Booking</th>
          <th className="font-weight-bold">Date Booking</th>
          <th className="font-weight-bold">Date First departure</th>
          <th className="font-weight-bold">Date TTL</th>
          <th className="font-weight-bold">Date Ticketing</th>
          <th className="font-weight-bold">Notifications</th>
          <th className="font-weight-bold">Code</th>
          <th className="font-weight-bold">Text</th>
          <th className="font-weight-bold">Carrier</th>
          <th className="font-weight-bold">Segments</th>
          <th className="font-weight-bold">Passengers</th>
          <th className="font-weight-bold">First Name</th>
          <th className="font-weight-bold">Last Name</th>
          <th className="font-weight-bold">Type</th>
        </tr>
      )}
    </thead>
  );
};

export default BookingMangerTable;

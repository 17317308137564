import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { useBookingClient } from "hooks/useHttpClient";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface ConfirmTicketModalProps {
  Booking: any;
  Toggle: () => void;
  IsOpen: boolean;
  OnSync: (callback?: () => void) => void;
  GetBookingsWithCurrentFilter: () => void;
}

const BookingDeprecatedModal: React.FC<ConfirmTicketModalProps> = ({
  Booking,
  Toggle,
  IsOpen,
  OnSync,
  GetBookingsWithCurrentFilter,
}) => {
  const [loading, setLoading] = useState(false);
  const bookClient = useBookingClient();

  const handleDeprecate = async () => {
    setLoading(true);

    const identifier = Booking?.latestVersion?.bookingIdentifier;

    if (!identifier) {
      toast.error("Booking identifier is missing!");
      setLoading(false);
      return;
    }

    try {
      await bookClient.deprecateBooking(identifier);
      toast.success("Booking deprecated successfully!");

      // Ensure OnSync is called after successful deprecation
      // await OnSync();

      // Refresh bookings list after changes
      GetBookingsWithCurrentFilter();

      Toggle(); // Close modal after successful operation

    } catch (error) {
      console.error("Error in Deprecating Booking:", error);
      toast.error("Failed to deprecate booking. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      size="lg"
      isOpen={IsOpen}
      toggle={!loading ? Toggle : undefined} // Prevent closing while loading
      centered
      className="customPopupDesign"
    >
      <ModalHeader toggle={!loading ? Toggle : undefined} className="text-xl font-semibold">
        Booking Deprecated for{" "}
        <strong>{Booking?.latestVersion?.generalInfo?.filekey || "N/A"}</strong>
      </ModalHeader>
      <ModalBody className="p-6 text-center">
        {loading ? (
          <div className="flex flex-col items-center justify-center gap-4">
            <Spinner color="primary" size="lg" />
            <p className="text-gray-700 text-lg">Processing... Please wait.</p>
          </div>
        ) : (
          <>
            <p style={{ color: "#4a5568", fontSize: "20px" }} className="mb-4">
              Are you sure you want to deprecate this booking?
            </p>

            <div className="flex justify-center gap-4">
              <Button
                color="danger"
                className="text-white flex items-center"
                block
                onClick={handleDeprecate}
                disabled={loading} // Disable button while loading
              >
                {loading ? (
                  <Spinner size="sm" color="light" className="mr-2" />
                ) : (
                  <FontAwesomeIcon style={{ color: "white" }} icon={faTrash} />
                )}
                <span className="ml-2">YES, Deprecate this Booking</span>
              </Button>
            </div>
          </>
        )}
      </ModalBody>
      {!loading && (
        <ModalFooter>
          <Button color="secondary" onClick={Toggle} disabled={loading}>
            Close
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default BookingDeprecatedModal;

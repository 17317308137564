import { faElementor } from "@fortawesome/free-brands-svg-icons";
import {
  faBan,
  faChair,
  faDownload,
  faExclamation,
  faFileAlt,
  faInfoSquare,
  faMailForward,
  faNetworkWired,
  faRotateExclamation,
  faStar,
  faTicket,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useBookingClient } from "hooks/useHttpClient";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { AgentPlusBookingManagerFeature, BookingItemModel } from "WebApiClient";
import { useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";

interface BookingMangerTableRowActionsProps {
  Booking: BookingItemModel;
  OnUpdateBooking: (data: BookingItemModel) => void;
  NotificationsModalOpen: boolean;
  ToggleSplitBookingModal: () => void;
  ToggleSetBookingAsDeprecated: () => void;
  ToggleNotificationModal: () => void;
  ToggleDetailsModal: () => void;
  OnSync: () => void;
  Label?: string;
  IsFetching: boolean;
  seatmapModalOpen?: boolean;
  setMapToggle?: () => void;
  serviceModalOpen?: boolean;
  serviceToggle?: () => void;
  cancelModalOpen?: boolean;
  cancelTicketModal?: () => void;
  cancelModal?: () => void;
  ElementsModal?: () => void;
  ticketModal?: () => void;
  ConfirmEmailSend?: () => void;
  ToggleFareModal: () => void;
  ToggleBookingCommunicationModal: () => void;
  EmailSendModalOpen?: boolean;

}
const BookingMangerTableRowActions: React.FC<
  BookingMangerTableRowActionsProps
> = (props) => {
  const [actionsDropDownOpen, setActionsDropDownOpen] = useState(false);
  const data = props.Booking.latestVersion!;
  const actions = data.allowedActions;
  const bookingClient = useBookingClient();
  const [isConfirmEmail, setIsConfirmEmail] = useState<boolean>(false);

  const otherSettings: string = useSelector((state: State) => {
    if (
      state.Session.FrontendSettings.otherSettingsAsKeyValueText == null ||
      state.Session.FrontendSettings.otherSettingsAsKeyValueText.trim() === ""
    ) {
      return state.Session.FrontendSettings.otherSettingsAsKeyValueText ===
        "menuPosition=L\nshowFareHeader=Off"
        ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse"
        : 'menuPosition=L/{"textColor":"#ffffff","bgColor":"#242424"}showFareHeader=OffshowSmartCalender=None/n[]/nfalse/nfalse/n{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}0{"PassengerGender":"Unselected","PassengerDOB":"No"}showHotelTab=All{"Container":"#BRANCHCHANGE#"}showCabinContainer=None';
    }
    return state.Session.FrontendSettings.otherSettingsAsKeyValueText;
  });

  function DownloadBackOffice() {

    bookingClient
      .downloadBackOfficeFile(data.bookingIdentifier!)
      .then((e) => {
        const fileName = `${data.generalInfo!.filekey}_bo.xml`;
        const a = document.createElement("a");
        a.href = URL.createObjectURL(e!.data);
        a.download = fileName;
        a.click();
        a.remove();
        toast.success("Backoffice file saved to disk.");
      })
      .catch(() => {
        toast.error("An error occured while downloading Backoffice file.");
      })

  }

  let settingArr = otherSettings.split("\n");
  const fieldContainer =
    settingArr[7] != undefined ? settingArr[7].split("#") : [];

  useEffect(() => {
    try {

      const isConfirm = fieldContainer.includes("CONFIRMATIONMAIL");
      setIsConfirmEmail(isConfirm);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      setIsConfirmEmail(false); // Handle parsing error
    }
  }, [fieldContainer]);

  return (

    <Dropdown
      isOpen={actionsDropDownOpen}
      toggle={() => setActionsDropDownOpen(!actionsDropDownOpen)}>
      <DropdownToggle
        className="text-white"
        color="primary"
        size="sm"
        caret
        disabled={props.IsFetching}>
        {props.Label && <span>{props.Label}</span>}
      </DropdownToggle>
      <DropdownMenu>
        {actions.includes(AgentPlusBookingManagerFeature.RefreshPnr) &&
          props.OnSync ? (
          <DropdownItem onClick={props.OnSync}>
            <FontAwesomeIcon icon={faRotateExclamation} />&nbsp; Refresh PNR
          </DropdownItem>
        ) : null}
        {actions.includes(AgentPlusBookingManagerFeature.ManageElements) &&
          props.Booking.latestVersion.generalInfo.bookingStatus !== 16 && (
            <DropdownItem onClick={props.ElementsModal}>
              <FontAwesomeIcon icon={faElementor} />
              &nbsp;&nbsp;Manage elements
            </DropdownItem>
          )}
        {actions.includes(AgentPlusBookingManagerFeature.ManageServices) &&
          props.Booking.latestVersion.generalInfo.bookingStatus !== 16 && (
            <DropdownItem onClick={props.serviceToggle}>
              <FontAwesomeIcon icon={faStar} />&nbsp; Manage services
            </DropdownItem>
          )}
        {actions.includes(AgentPlusBookingManagerFeature.TicketIssue) &&
          props.Booking.latestVersion.generalInfo.bookingStatus !== 16 && (
            <DropdownItem onClick={props.ticketModal}>
              <FontAwesomeIcon icon={faTicket} />&nbsp; Ticket/EMD
            </DropdownItem>
          )}
        {actions.includes(AgentPlusBookingManagerFeature.Seatmap) &&
          props.Booking.latestVersion.generalInfo.bookingStatus !== 16 && (
            <DropdownItem onClick={props.setMapToggle}>
              <FontAwesomeIcon icon={faChair} />
              &nbsp;&nbsp;Seatmap
            </DropdownItem>
          )}
        {actions.includes(
          AgentPlusBookingManagerFeature.FareRules
        ) && (
            <DropdownItem onClick={props.ToggleFareModal}>
              <FontAwesomeIcon icon={faFileAlt} /> &nbsp; Fare Rules
            </DropdownItem>
          )}

        {actions.includes(
          AgentPlusBookingManagerFeature.SplitBook
        ) && (
            <DropdownItem color="primary" onClick={props.ToggleSplitBookingModal}>
              <FontAwesomeIcon icon={faInfoSquare} /> &nbsp;Split booking
            </DropdownItem>
          )}
        {actions.includes(
          AgentPlusBookingManagerFeature.SetBookingAsDeprecated
        ) && (
            <DropdownItem color="primary" onClick={props.ToggleSetBookingAsDeprecated}>
              <FontAwesomeIcon icon={faInfoSquare} /> &nbsp; Set booking deprecated
            </DropdownItem>
          )}
        {actions.includes(AgentPlusBookingManagerFeature.CancelBooking) &&
          props.Booking.latestVersion.generalInfo.bookingStatus !== 16 && (
            <DropdownItem onClick={props.cancelModal}>
              <FontAwesomeIcon icon={faBan} style={{ color: "red" }} />&nbsp; Cancel
              booking
            </DropdownItem>
          )}
        {actions.includes(AgentPlusBookingManagerFeature.VoidCancelTicket) &&
          props.Booking.latestVersion.generalInfo.bookingStatus !== 16 && (
            <DropdownItem onClick={props.cancelTicketModal}>
              <FontAwesomeIcon icon={faBan} style={{ color: "red" }} />{" "}
              &nbsp;Void/Cancel ticket
            </DropdownItem>
          )}


        <DropdownItem onClick={props.ToggleNotificationModal}>
          &nbsp;
          <FontAwesomeIcon icon={faExclamation} />
          &nbsp;&nbsp;Notifications
        </DropdownItem>

        {actions.includes(
          AgentPlusBookingManagerFeature.FAPICommunication
        ) && (
            <DropdownItem onClick={props.ToggleBookingCommunicationModal}>
              <FontAwesomeIcon icon={faNetworkWired} />&nbsp; FAPI Communication
            </DropdownItem>
          )}
        {isConfirmEmail && (
          <DropdownItem onClick={props.ConfirmEmailSend}>
            <FontAwesomeIcon icon={faMailForward} />&nbsp; Send confirmation
          </DropdownItem>
        )}

        {actions.includes(
          AgentPlusBookingManagerFeature.DownloadBackOfficeFile
        ) && (
            <DropdownItem onClick={DownloadBackOffice}>
              <FontAwesomeIcon icon={faDownload} />&nbsp; Download BO file
            </DropdownItem>
          )}
      </DropdownMenu>
    </Dropdown>

  );
};

export default BookingMangerTableRowActions;
